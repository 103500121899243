<template>
  <div class="companyInfo">
    <teleport
      v-if="!isPreviewPageFromViewProfile && mountedComponent"
      to="#header-nav"
    >
      <CBreadcrumbItem active> Info </CBreadcrumbItem>
    </teleport>

    <CCard
      ><CCardBody class="p-lg-4 p-0">
      <div v-if="info.status && info.status !== 'active'" class="alert alert-danger text-center" role="alert">
        <strong>
          Account deleted
        </strong>
      </div>
        <CAccordion>
          <AccordionItem :mobile-label="'General Info'" :item-key="1">
            <div
              class="companyInfo__updated justify-content-md-end justify-content-center"
            >
              Last Activity: {{ info.last_modified_date }}
            </div>
            <CRow>
              <CCol md="12" lg="4" xl="2" class="d-flex justify-content-center">
                <Avatar
                  :rounded="false"
                  class="companyInfo__logo"
                  :src="info.logo"
                  :display-name="[info.company_name]" />
              </CCol>

              <CCol md="12" lg="4" xl="5">
                <div class="companyInfo__row flex-md-row flex-column">
                  <span>Company Name</span><span>{{ info.company_name }}</span>
                </div>
                <div class="companyInfo__row flex-md-row flex-column">
                  <span>DBA</span><span>{{ info.dba }}</span>
                </div>
                <div class="companyInfo__row flex-md-row flex-column">
                  <span>Website</span
                  ><span
                    v-if="info.website"
                    style="
                      color: #0068ad !important;
                      margin-left: 10px;
                      text-decoration: underline;
                      cursor: pointer;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 72.5%;
                    "
                    @click="goWebsite(info.website)"
                    >{{ info.website }}</span
                  >
                </div>
                <div class="companyInfo__row flex-md-row flex-column">
                  <span>Company ID</span><span>{{ info.company_id }}</span>
                </div>
                <div class="companyInfo__row flex-md-row flex-column">
                  <span>Tax IN</span><span>{{ info.tax }}</span>
                </div>
              </CCol>

              <CCol md="12" lg="4" xl="5">
                <div class="companyInfo__row flex-md-row flex-column">
                  <span>Primary Contact</span
                  ><router-link
                    v-if="info.primary_contact_id"
                    :to="{
                      name: 'CompanyUser',
                      params: {
                        user_id: info.primary_contact_id,
                        id: $route.params.id,
                      },
                    }"
                    >{{ info.primary_contact_name }}</router-link
                  >
                </div>
                <div class="companyInfo__row flex-md-row flex-column">
                  <span>Default Contract Signer</span
                  ><router-link
                    v-if="info.default_contract_signer_id"
                    :to="{
                      name: 'CompanyUser',
                      params: {
                        user_id: info.default_contract_signer_id,
                        id: $route.params.id,
                      },
                    }"
                    >{{ info.default_contract_signer_name }}</router-link
                  >
                </div>
                <div class="companyInfo__row flex-md-row flex-column">
                  <span>Default Task Assignee</span
                  ><router-link
                    v-if="info.default_task_assignee_id"
                    :to="{
                      name: 'CompanyUser',
                      params: {
                        user_id: info.default_task_assignee_id,
                        id: $route.params.id,
                      },
                    }"
                    >{{ info.default_task_assignee_name }}</router-link
                  >
                </div>
                <div class="companyInfo__row flex-md-row flex-column">
                  <span>Company Type</span>
                  <!-- <router-link v-if="info.company_type && one_project" :to="{name: 'ProjectDetails', params: { id: one_project }}">{{info.company_type}}</router-link> -->
                  <!-- <router-link v-else-if="info.company_type" :to="{name: 'ProjectsList'}">{{info.company_type}}</router-link> -->
                  <span>{{ info.company_type }}</span>
                </div>
              </CCol>
            </CRow>
          </AccordionItem>
          <AccordionItem :label="'Certification'" :item-key="2">
            <SmartTable
              :table-name="'certifications'"
              :items="certifications"
              :columns="
                certificationsFields.map((i) => ({ ...i, sorter: false }))
              "
              :loading="loadingTable"
              :clear-text="'No Data yet'"
              infinity
              class="mb-4"
            >
              <template #file="{ item }">
                <td>
                  <CIcon
                    v-if="item.path"
                    name="cil-file"
                    class="pointer"
                    @click="goWebsite(item.path)"
                  />
                  <span v-else class="white-space-nowrap">File not found</span>
                </td>
              </template>
            </SmartTable>
          </AccordionItem>
          <AccordionItem :label="'Licence'" :item-key="3">
            <SmartTable
              :table-name="'licenses'"
              :items="licenses"
              :columns="licensesFields.map((i) => ({ ...i, sorter: false }))"
              :loading="loadingTable"
              :clear-text="'No Data yet'"
              infinity
              class="mb-4"
            >
              <template #file="{ item }">
                <td>
                  <CIcon
                    v-if="item.path"
                    name="cil-file"
                    class="pointer"
                    @click="goWebsite(item.path)"
                  />
                  <span v-else class="white-space-nowrap">File not found</span>
                </td>
              </template>
            </SmartTable>
          </AccordionItem>

          <AccordionItem :label="'Subsidiary Companies'" :item-key="3">
            <SmartTable
              :table-name="'subsidiary'"
              :items="subsidiary"
              :columns="subsidiaryFields.map((i) => ({ ...i, sorter: false }))"
              :loading="loadingTable"
              :clear-text="'No Data yet'"
              infinity
              class="mb-4"
            >
              <template #file="{ item }">
                <td>
                  <CIcon
                    v-if="item.path"
                    name="cil-file"
                    class="pointer"
                    @click="goWebsite(item.path)"
                  />
                  <span v-else class="white-space-nowrap">File not found</span>
                </td>
              </template>
            </SmartTable>
          </AccordionItem>


          <AccordionItem
            :label="'Office Locations'"
            :item-key="4"
          >
            <SmartTable
              :table-name="'office'"
              :items="office"
              :columns="officeFields.map((i) => ({ ...i, sorter: false }))"
              :loading="loadingTable"
              :clear-text="'No Data yet'"
              infinity
              class="mb-4"
            >
              <template #is_headquarters="{ item }">
                <td>
                  {{ item.is_headquarters ? 'Yes' : 'No' }}
                </td>
              </template>
              <template #action="{ item }">
                <td>
                  <CIcon
                    v-if="item.path"
                    name="cil-file"
                    class="pointer"
                    @click="goWebsite(item.path)"
                  />
                  <span v-else class="white-space-nowrap">File not found</span>
                </td>
              </template>
            </SmartTable>
          </AccordionItem>
        </CAccordion>
      </CCardBody></CCard
    >
  </div>
</template>

<script src="./script.js"></script>
<style lang="scss">
@import 'style';
</style>
