import AccordionItem from '@/components/Page/AccordionItem'
import Avatar from "@/components/Avatar.vue";

export default {
  components: {Avatar, AccordionItem },
  inject: ['mountedComponent'],
  props: {
    isPreviewPageFromViewProfile: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.fetchData()
  },
  data() {
    return {
      loadingTable: false,
      // one_project: false,
      info: {
        logo: null,
        company_name: '',
        dba: '',
        website: '',
        company_id: '',
        tax: '',
        primary_contact_id: null,
        primary_contact_name: '',
        default_contract_signer_id: null,
        default_contract_signer_name: '',
        default_task_assignee_id: null,
        default_task_assignee_name: '',
        company_type: '',
        last_modified_date: null,
      },
      certificationsFields: [
        { key: 'name', label: 'Certifications' },
        { key: 'type', label: 'Type' },
        { key: 'number', label: 'Cert. #' },
        {
          key: 'exp_date',
          label: 'Expirations',
          format: (value) => (value ? this.$BtoF({ value }) : null),
        },
        { key: 'agency', label: 'Agency' },
        { key: 'note', label: 'Note' },
        { key: 'file', label: 'File' },
      ],
      certifications: [],
      licensesFields: [
        { key: 'company_name', label: 'Company' },
        { key: 'state_name', label: 'State' },
        { key: 'number', label: 'Licence Number' },
        { key: 'type', label: 'Licence Class/Type' },
        {
          key: 'exp_date',
          label: 'Expiration Date',
          format: (value) => (value ? this.$BtoF({ value }) : null),
        },
        { key: 'file', label: 'File' },
      ],
      licenses: [],
      // officeFields: [
      //   { key: 'name', label: 'Office' },
      //   { key: 'address', label: 'Street Address' },
      //   { key: 'city', label: 'City' },
      //   { key: 'state', label: 'State' },
      //   { key: 'zip_code', label: 'Zip' },
      //   { key: 'country', label: 'Country' },
      //   { key: 'phone', label: 'Phone' },
      //   { key: 'fax', label: 'Fax' },
      //   { key: 'is_headquarters', label: 'Headquarters' },
      //   { key: 'number', label: '# or Projects' },
      //   { key: 'action', label: 'W9' },
      // ],
      officeFields: [
        { key: 'name', label: 'Office' },
        { key: 'address', label: 'Street Address' },
        { key: 'city', label: 'City' },
        { key: 'state', label: 'State' },
        { key: 'county', label: 'County' },
        { key: 'zip_code', label: 'Zip' },
        { key: 'country', label: 'Country' },
        { key: 'phone', label: 'Phone' },
        { key: 'fax', label: 'Fax' },
        {
          key: 'is_headquarters',
          label: 'Headquarters',
          format: (value) => (value ? 'Yes' : 'No'),
        },
        {
          key: 'path',
          label: 'File',
          _props: { class: 'tableIconCenter' },
          _style: 'min-width: 10px',
        },
      ],
      office: [],
      subsidiaryFields: [
        {
          key: 'name',
          label: 'Company name',
          sorter: false,
        },
        {
          key: 'dba',
          label: 'DBA',
          sorter: false,
        },
        {
          key: 'website',
          label: 'Website',
          sorter: false,
        },
        {
          key: 'tax',
          label: 'Tax ID',
          sorter: false,
        },
        {
          key: 'primary_contact_name',
          label: 'Primary contact',
          sorter: false,
        },
        {
          key: 'assignee_name',
          label: 'Default assignee',
          sorter: false,
        },
        {
          key: 'signer_name',
          label: 'Default signer',
          sorter: false,
        },
        {
          key: 'attachment_path',
          label: 'File',
          sorter: false,
          _props: { class: 'tableIconCenter' },
          _style: 'min-width: 10px',
        },
      ],
      subsidiary: [],
    }
  },
  methods: {
    // ...mapActions(['fetchProjects']),
    fetchData() {
      this.loadingTable = true
      this.$http.companies
        .getCompanyInfo({ params: { company_id: this.$route.params.id } })
        .then((res) => {
          this.loadingTable = false
          this.certifications = res.data.data.certifications
          this.licenses = res.data.data.licenses
          this.office = res.data.data.offices
          this.subsidiary = res.data.data.subsidiary_companies
          this.info = {
            logo: res.data.data.company.logo,
            company_name: res.data.data.company.name,
            dba: res.data.data.company.dba,
            website: res.data.data.company.website,
            company_id: res.data.data.company.id,
            tax: res.data.data.company.tid,
            primary_contact_name: res.data.data.company.primary_contact_name,
            primary_contact_id: res.data.data.company.primary_contact_id,
            default_contract_signer_name:
              res.data.data.company.default_contract_signer_name,
            default_contract_signer_id:
              res.data.data.company.default_contract_signer_id,
            default_task_assignee_name:
              res.data.data.company.default_task_assignee_name,
            default_task_assignee_id:
              res.data.data.company.default_task_assignee_id,
            company_type: res.data.data.company.type,
            last_modified_date: this.$BtoF({
              value: res.data.data.last_modified_date.date,
              time: true,
            }),
            status: res.data.data.company.status
          }
        })
        .catch(() => {
          this.loadingTable = false
        })
    },
    goWebsite(url) {
      window.open(url[0] !== 'h' ? 'https://' + url : url, '_blank')
    },
  },
}
